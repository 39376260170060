import React from 'react'
import { RichText } from 'prismic-reactjs'

import { getAnimation } from '../../utils'

import './styles.css'

const Form = ({ isSuccess, data }) => {
  if (isSuccess) return <RichText render={data?.contact_success?.raw} />

  return (
    <>
      <RichText render={data?.contact_form?.raw} />
      <form name="Contacto" method="POST" data-netlify="true" action="/gracias">
        <input type="hidden" name="form-name" value="Contacto" />
        <div className="contacto__form__group">
          <label htmlFor="Nombre">nombre*</label>
          <input type="text" id="Nombre" name="Nombre" required />
        </div>
        <div className="contacto__form__group">
          <label htmlFor="Teléfono">teléfono</label>
          <input type="tel" id="Teléfono" name="Teléfono" />
        </div>
        <div className="contacto__form__group">
          <label htmlFor="Email">email*</label>
          <input type="email" id="Email" name="Email" required />
        </div>
        <div className="contacto__form__group">
          <label htmlFor="Mensaje">mensaje</label>
          <textarea name="Mensaje" id="Mensaje" cols="20" rows="4" />
        </div>
        <button type="submit">enviar</button>
      </form>
    </>
  )
}

const Paragraph = ({ contact = [] }) => contact.map((item, index) => (
  <div key={`pg-${index}`}>
    <div className="contacto__title">
      <RichText render={item?.contact_title?.raw} />
    </div>
    <RichText render={item?.contact_description?.raw} />
  </div>
))

const ContactoContent = ({ isSuccess, data }) => (
  <div className="contacto" {...getAnimation(0)}>
    <div className="contacto__info">
      <Paragraph contact={data?.contact} />
    </div>
    <div className="contacto__form">
      <Form {...{ isSuccess }} {...{ data }} />
    </div>
  </div>
)

export default ContactoContent