import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'

import nosotrosImg from '../../images/nosotros-black.png'

import './styles.css'

const Address = ({ hideAddress, data }) => {
  if (hideAddress) return null

  return (
    <address className="contact__address">
      <RichText render={data?.address?.raw} />
    </address>
  )
}

const Contact = ({ hideAddress, data }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.src = nosotrosImg
    img.onload = () => setLoaded(true)
  }, [])

  const getClassName = (hideAddress, loaded) => {
    if (!loaded) return 'contact'
    if (!hideAddress) return 'contact contact--visible'
    return 'contact contact--visible contact--hide-address'
  }

  return (
    <div className={getClassName(hideAddress, loaded)}>
      <div className="contact__image">
        <img src={nosotrosImg} alt="Contáctanos" />
      </div>
      <Address {...{ hideAddress, data }} />
    </div>
  )
}


export default Contact